import "./src/styles/global.scss"
import "./src/styles/_SEOPage.scss"
import "./src/styles/_header.scss"
import "./src/styles/_footer.scss"
import "./src/styles/_homepage.scss"
import "./src/styles/_howItWorks.scss"
import "./src/styles/_services.scss"
import "./src/styles/_contactUs.scss"
import "./src/styles/_pricingPage.scss"
import "./src/styles/_prices.scss"
import "./src/styles/_webDesignPage.scss"
import "./src/styles/_digitalMarketingPage.scss"
import "./src/styles/_approachPage.scss"
import "./src/styles/_dandCService.scss"
import "./src/styles/_digitalMarketing.scss"
import "./src/styles/_designAndCreation.scss"
import "./src/styles/_SEO.scss"
import "./src/styles/_servicePage.scss"
import "./src/styles/_contactUsPage.scss"
import "./src/styles/_consultPage.scss"
