// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-approach-js": () => import("./../../../src/pages/Approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-consult-js": () => import("./../../../src/pages/Consult.js" /* webpackChunkName: "component---src-pages-consult-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../../../src/pages/DigitalMarketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/Pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/SEO.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-web-design-js": () => import("./../../../src/pages/WebDesign.js" /* webpackChunkName: "component---src-pages-web-design-js" */)
}

